import Layout from '../components/layout'
import dynamic from 'next/dynamic';
import {preInit, getPreJs, preView, redtrackInit} from "../utils/redtrack";
import {useEffect} from "react";

const Header = dynamic(() => import('../components/pages/home/header'));
const Footer = dynamic(() => import('../components/pages/common/footerWidget'));
const FAQs = dynamic(() => import('../components/pages/home/faqs'));


function Home() {
    useEffect(() => {
        redtrackInit();
    }, []);

    return (
        <Layout title={process.env.NEXT_PUBLIC_WEBSITE_NAME} description={"Extend your tax return though October 15th"}
                preHeader={<Header/>}>

            <FAQs/>
            <Footer/>
        </Layout>
    )
}

export default Home;
